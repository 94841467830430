export const vertices = [
  [96, 159],
  [92, 162],
  [87, 168],
  [82, 176],
  [81, 178],
  [83, 185],
  [79, 192],
  [74, 201],
  [76, 209],
  [73, 217],
  [70, 226],
  [71, 234],
  [72, 243],
  [71, 250],
  [71, 258],
  [72, 271],
  [71, 277],
  [71, 284],
  [70, 291],
  [70, 298],
  [69, 305],
  [67, 311],
  [66, 319],
  [65, 327],
  [64, 335],
  [63, 342],
  [63, 350],
  [63, 358],
  [65, 368],
  [63, 380],
  [62, 390],
  [62, 401],
  [60, 410],
  [62, 418],
  [68, 425],
  [67, 434],
  [72, 439],
  [76, 445],
  [82, 453],
  [87, 462],
  [98, 470],
  [108, 469],
  [111, 473],
  [119, 482],
  [127, 486],
  [136, 487],
  [144, 492],
  [153, 497],
  [157, 506],
  [162, 515],
  [167, 522],
  [178, 527],
  [183, 530],
  [192, 534],
  [200, 539],
  [208, 542],
  [212, 545],
  [216, 546],
  [217, 551],
  [219, 557],
  [222, 559],
  [226, 561],
  [228, 564],
  [231, 567],
  [234, 568],
  [238, 568],
  [239, 565],
  [240, 563],
  [239, 556],
  [243, 557],
  [242, 551],
  [241, 546],
  [238, 543],
  [235, 540],
];
